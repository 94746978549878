<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-07 14:53:26
 * @LastEditors:
 * @LastEditTime: 2023-05-07 14:53:26
-->
<template>
  <Dialog
    :title="'【' + teacherInfo.username + '】设置学科'"
    confirmText="保 存"
    width="420px"
    height="200px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-select
      v-model="subjectIds"
      multiple
      placeholder="请选择"
      v-loading="loading"
      style="width: 100%"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      teacherInfo: {}, // 教师信息
      subjectIds: [],
      options: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开窗口
    async open(info) {
      this.visible = true;
      this.teacherInfo = info;
      this.subjectIds = info["subject"]
        ? info["subject"].map((v) => {
            return v.id;
          })
        : [];
      await this.getClassOptions();
    },

    // 确定设置为班主任
    handleConfirm() {
      let params = {
        teacherId: this.teacherInfo.id,
        subjectIds: this.subjectIds,
      };
      this.$cloud.post("/user/set_subject", params).then(() => {
        this.$message.success("设置学科成功！");
        this.visible = false;
        this.$emit("refresh");
      }).catch(err=>{
        this.$message.error(err)
      });
    },

    // 获取班级待选项
    getClassOptions() {
      this.loading = true;
      this.$cloud
        .get("/subject/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            this.options = res.list.map((v) => {
              return {
                label: v.name,
                value: v.id,
              };
            });
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
