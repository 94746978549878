<template>
  <div v-loading="loading">
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/user/list"
      @onSelectionChange="handleOnSelectionChange"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        ></Search>
      </template>
      <template #action>
        <el-button type="success" @click="handleCreateTeacher">新增</el-button>
        <el-button type="primary" @click="handleExportTemp"
          >导出教师信息采集模板</el-button
        >
        <el-upload
          action=""
          :show-file-list="false"
          style="display: contents"
          accept=".xls,.XLS,.xlsx,.XLSX"
          :http-request="handleImportTemp"
        >
          <el-button icon="el-icon-upload2" class="ml-1">导入信息</el-button>
        </el-upload>
      </template>
      <template #table-item-role="{ row }">
        {{ row["role"] === "admin" ? "管理员" : "教师" }}
      </template>

      <template #table-item-gender="{ row }">
        {{
          row["gender"] === "MALE"
            ? "男"
            : row["gender"] === "FEMALE"
            ? "女"
            : "-"
        }}
      </template>

      <template #table-item-subject="{ row }">
        {{ onSchedule(row["schedule"]) }}
      </template>
      <template #table-item-master="{ row }">
        <template
          v-if="
            row['masterRoom'] &&
            row['masterRoom'].level &&
            row['masterRoom'].name
          "
        >
          {{ row["masterRoom"] ? row["masterRoom"].level : "" }} 年级
          {{ row["masterRoom"] ? row["masterRoom"].level : ""
          }}{{
            row["masterRoom"]
              ? row["masterRoom"].name.length > 1
                ? row["masterRoom"].name
                : "0" + row["masterRoom"].name
              : ""
          }}
          班
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleLogin(row)">登录</el-button>
        <el-button type="text" @click="handleUpdateTeacher(row)"
          >修改</el-button
        >
        <el-button type="text" @click="handleSetClassTeacher(row)"
          >设置班主任</el-button
        >
        <el-button type="text" @click="handleSetSubjectTeacher(row)"
          >设置学科</el-button
        >
        <el-button type="text" @click="handleResetPassword(row)"
          >密码重置</el-button
        >
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteTeacher(row)"
          >删除</el-button
        >
      </template>
    </Main>
    <Edit ref="edit" @refresh="onRefresh"></Edit>
    <ClassTeacher ref="classTeacher" @refresh="onRefresh"></ClassTeacher>
    <subject-teacher
      ref="subjectTeacher"
      @refresh="onRefresh"
    ></subject-teacher>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import Edit from "./components/edit.vue";
import ClassTeacher from "./components/classTeacher.vue";
import subjectTeacher from "./components/subjectTeacher.vue";
import { columns, searchOptions } from "./config";
import xlsx from "xlsx";
import Cookies from "js-cookie";
export default {
  name: "",
  components: { Main, Search, Edit, ClassTeacher, subjectTeacher },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      loading: false,
      defaultPassword: "123456", // 默认密码
      searchForm: {},
      selectRows: [],
    };
  },
  created() {},
  mounted() {
    this.onFind();
  },
  methods: {
    // 一键登录
    handleLogin(row) {
      let teacherId = row.id;
      console.log("登录", row);
      this.$message.info("登录中");
      this.$cloud.post("user/fast/login", { teacherId }).then((res) => {
        console.log("res", res);
        this.$message.success("登录成功");
        Cookies.remove("hankun_cloud_template_token");
        Cookies.set("hankun_cloud_template_token", res.token);
        let path = window.location.origin + window.location.pathname + "#/pages/home/home"
        console.log('path',path);
        window.location.replace(path);
      });
    },
    onParams() {
      return this.searchForm;
    },
    /**
     *  @actions:  查询表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-04-26 11:28:55
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onFind() {
      this.$refs["main"].onFind();
    },

    /**
     *  @actions:  列表中展示学科
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 23:29
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onSchedule(schedules) {
      let name = "-";
      if (schedules && schedules.length) {
        let arrays = [];
        name = schedules.map((item) => {
          let partIndex = null;
          if (item?.subject?.name === "班主任") {
            return;
          }
          arrays.map((part, index) => {
            if (part.name === item?.subject?.name) {
              partIndex = index;
            }
          });
          if (partIndex || partIndex === 0) {
            arrays[partIndex].class +=
              ", " +
              item.Room?.level +
              (item.Room && item.Room?.name && item.Room?.name.length > 1
                ? item.Room.name
                : "0" + item.Room.name);
          } else {
            arrays.push({
              name: item?.subject?.name,
              class:
                item.Room?.level +
                (item.Room && item.Room?.name && item.Room?.name.length > 1
                  ? item.Room.name
                  : "0" + item.Room.name),
            });
          }
        });
        return arrays
          .map((item) => {
            return item["name"] + "（" + item.class + "）";
          })
          .join(" ");
      }
      return name;
    },

    /**
     *  @actions:  导出教师信息采集模板
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/30 22:20
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleExportTemp() {
      let link = document.createElement("a");
      link.setAttribute("download", "教师信息采集表.xlsx");
      link.href = "/tempalte.xlsx"; // 你本地资源文件的存放地址
      link.click();
    },

    /**
     *  @actions:  导入教师信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/30 22:21
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleImportTemp(e) {
      let file = e.file; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader(); // 读取文件
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = xlsx.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];

          let list = [];
          // 转换成json数据
          const exl = xlsx.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          // 打印 ws 就可以看到读取出的表格数据
          console.log("上传后的文件", exl);
          if (exl && exl.length) {
            exl.map((item) => {
              if (
                item["__EMPTY"] &&
                item["__EMPTY_1"] &&
                typeof item["__EMPTY_1"] === "number"
              ) {
                let arrays = String(item["__EMPTY_3"]).split("");
                let room = "";
                let str = "";
                for (const itemKey in arrays) {
                  if (
                    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
                      arrays[itemKey]
                    )
                  ) {
                    str += arrays[itemKey];
                    if (str.length > 2) {
                      room += str + ",";
                      str = "";
                    }
                  }
                }

                let obj = {
                  username: item["__EMPTY"]
                    ? item["__EMPTY"].replace(/\s*/g, "")
                    : "",
                  mobile: item["__EMPTY_1"] || "",
                  subject: item["__EMPTY_2"]
                    ? item["__EMPTY_2"].replace(/\s*/g, "")
                    : "",
                  room: room.slice(0, room.length - 1) || "",
                  master: item["__EMPTY_4"] || "",
                };
                list.push(obj);
              }
            });
            console.log("list::", list);
            this.loading = true;
            this.$cloud
              .post("/schedule/import", {
                data: list,
              })
              .then(() => {
                this.loading = false;
                this.$message.success("上传成功！");
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
            // console.log("最终数据：：", list);
          }
        } catch (e) {
          console.log("error", e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },

    /**
     *  @actions:    新增教师
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 10:44:11
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateTeacher() {
      this.$refs["edit"].open({
        mode: 1, // 1 新增  2 修改
      });
    },

    /**
     *  @actions:    更新教师信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 12:53:02
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdateTeacher(row) {
      this.$refs["edit"].open({
        mode: 2, // 1 新增 2修改
        editform: row,
      });
    },

    /**
     *  @actions:    设置班主任
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-07 14:51:31
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSetClassTeacher(row) {
      this.$refs["classTeacher"].open(row);
    },

    /**
     *  @actions:    设置班主任
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-07 14:51:31
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSetSubjectTeacher(row) {
      this.$refs["subjectTeacher"].open(row);
    },

    /**
     *  @actions:    密码重置
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 13:25:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleResetPassword(row) {
      this.$confirm("确定重置当前教师的密码吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.$cloud
          .post("/user/reset", {
            id: row.id,
            password: this.defaultPassword,
          })
          .then(() => {
            this.$message.success("密码重置成功！");
          });
      });
    },

    /**
     *  @actions:    删除教师信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 13:25:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDeleteTeacher(row) {
      this.$confirm("确定删除该教师吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud
          .get("/user/delete", { id: row["id"] })
          .then(() => {
            this.$message.success("删除成功！");
            this.onRefresh();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:    表格选中的数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 13:21:10
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleOnSelectionChange(data) {
      this.selectRows = data;
    },

    /**
     *  @actions:    刷新
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023-05-04 12:48:14
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRefresh() {
      this.$refs["main"].onQuery();
    },
  },
};
</script>
<style lang="scss" scoped></style>
