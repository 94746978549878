export const columns = [
  {
    field: "mobile",
    label: "手机号",
    align: "left",
    width: "120,",
  },
  {
    field: "username",
    label: "姓名",
    align: "left",
    width: "120,",
  },
  {
    field: "role",
    label: "角色",
    align: "left",
    width: "80,",
  },
  {
    field: "gender",
    label: "性别",
    align: "left",
    width: "80,",
  },
  {
    field: "subject",
    label: "学科",
    align: "left",
    width: "auto,",
  },
  {
    field: "master",
    label: "班主任班级",
    align: "left",
    width: "120,",
  },
  {
    field: "birthday",
    label: "生日",
    align: "left",
    width: "120,",
  },
  {
    field: "created_time",
    label: "创建时间",
    align: "left",
    width: "150",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "280",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "username",
    label: "姓名",
    placeholder: "姓名查询",
  },
  {
    type: "input",
    field: "mobile",
    label: "手机号",
    placeholder: "账号查询",
  },
  {
    type: "radio",
    field: "isMaster",
    label: "班主任",
    multiple: false,
    placeholder: "选择性别",
    options: [
      {
        id: "",
        name: "全部",
      },
      {
        id: "YES",
        name: "是",
      },
      {
        id: "NO",
        name: "否",
      },
    ],
  },
];
