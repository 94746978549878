<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-04 10:41:06
 * @LastEditors:
 * @LastEditTime: 2023-05-04 10:41:06
-->
<template>
  <Dialog
    :title="title"
    confirmText="保 存"
    custom-class="group-manage-dialog"
    width="600px"
    :height="mode === 1 ? '460px' : '360px'"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div v-loading="loading">
      <el-form
        :model="editform"
        :rules="rules"
        ref="editform"
        label-width="100px"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="姓名：" prop="username">
              <el-input v-model="editform['username']" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="手机号码：" prop="mobile">
              <el-input
                auto-complete="new-password"
                v-model="editform['mobile']"
                clearable
                :maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="mode === 1">
          <el-col :span="24">
            <el-form-item label="密码：" prop="password">
              <el-input
                show-password
                auto-complete="new-password"
                v-model="editform['password']"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="mode === 1">
          <el-col :span="24">
            <el-form-item label="确认密码：" prop="rePassword">
              <el-input
                show-password
                v-model="editform['rePassword']"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="生日：" prop="birthday">
              <el-date-picker
                style="width: 100%"
                v-model="editform['birthday']"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="性别：" prop="gender">
              <el-radio-group v-model="editform['gender']">
                <el-radio-button label="MALE">男</el-radio-button>
                <el-radio-button label="FEMALE">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      mode: 1, // 1：新增 2：修改
      title: "新增",
      visible: false,
      loading: false,
      editform: {
        username: "",
        mobile: "",
        password: "",
        rePassword: "",
        birthday: "",
        gender: "MALE",
      },
      rules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "请输入教师姓名！",
          },
        ],
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入手机号码！",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "请输入登录密码！",
          },
        ],
        rePassword: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.editform.password) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
            required: true,
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    open(data) {
      this.mode = data["mode"];
      this.editform = {};
      this.title = "新增";
      this.visible = true;
      if (this.mode === 2) {
        this.title = "修改";
        this.editform = JSON.parse(JSON.stringify(data["editform"]));
      }
    },

    // 确定按钮
    handleConfirm() {
      this.$refs["editform"].validate((vaild) => {
        if (vaild) {
          let message = "确定创建当前教师信息吗？",
            url = "/user/create";
          if (this.mode === 2) {
            message = "确定保存当前教师信息吗？";
            url = "/user/edit";
          }
          this.$confirm(message, "提示", {
            type: "warning",
          }).then(() => {
            this.loading = true;
            this.$cloud
              .post(url, this.editform)
              .then(() => {
                this.loading = false;
                this.$emit("refresh");
                if (this.mode === 2) {
                  this.$message.success("更新成功！");
                  this.visible = false;
                } else {
                  this.$message.success("创建成功！");
                  this.open({ mode: 1 });
                }
              })
              .catch((err) => {
                this.$message.error(err)
                this.loading = false;
              });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
