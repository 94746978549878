<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-07 14:53:26
 * @LastEditors:
 * @LastEditTime: 2023-05-07 14:53:26
-->
<template>
  <Dialog
    :title="'【' + teacherInfo.username + '】设置班主任'"
    confirmText="保 存"
    width="420px"
    height="200px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-cascader
      v-loading="loading"
      clearable
      v-model="roomId"
      style="width: 100%"
      :options="options"
    ></el-cascader>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      teacherInfo: {}, // 教师信息
      roomId: "",
      options: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开窗口
    async open(info) {
      this.visible = true;
      this.teacherInfo = info;
      this.roomId = [info.masterRoom?.level, info.masterRoomId];
      await this.getClassOptions();
    },

    // 确定设置为班主任
    handleConfirm() {
      let params = {
        teacherId: this.teacherInfo.id,
        roomId: this.roomId[1],
      };
      this.$cloud.post("/user/master", params).then(() => {
        this.$message.success("设置班主任成功！");
        this.visible = false;
        this.$emit("refresh");
      }).catch(err=>{
        this.$message.error(err)
      });
    },

    // 获取班级待选项
    getClassOptions() {
      this.loading = true;
      this.$cloud
        .get("/room/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            let arrays = [];
            res.list.map((item) => {
              let findIndex = null;
              arrays.map((part, index) => {
                if (part.value === item.level) {
                  findIndex = index;
                }
              });
              let label =
                String(item.level) +
                (item.name.length > 1 ? String(item.name) : "0" + item.name) +
                " 班";
              if (findIndex || findIndex === 0) {
                arrays[findIndex].children.push({
                  value: item["id"],
                  label: label,
                });
              } else {
                arrays.push({
                  label: item.level + "年级",
                  value: item.level,
                  children: [
                    {
                      value: item["id"],
                      label: label,
                    },
                  ],
                });
              }
            });
            this.options = arrays;
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
